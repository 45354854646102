<template>
  <div class="page-rooter-fff">
    <headeBar title="我的卡包" left-arrow @click-left="newAppBack" />
    <div class="page-content">
      <van-tabs border swipeable v-model="active" title-active-color="#242831" title-inactive-color="#6C727A"
        color="#242831" line-height="0.53vw" line-width="42vw" @change="changeTab">
        <van-tab title="使用中" name="USE">
          <div class="top-nav row-start-center">
            <div v-for="item in typeList" :key="item.title" @click="changeType(item.type)"
              :class="{ active: param.type === item.type }" class="row-center-center">{{ item.title }}</div>
          </div>
          <div class="list" v-if="cardList.length">
            <!-- <div v-if="warn" class="warn fw6 flex-none row-between-center">
              <img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/upgrade-icon.png" alt="">
              <p class="flex-auto f24">{{ warn.message }}</p>
              <div @click="jumpUpgrade" class="btn f20 row-center-center">{{ warn.buttonDescribe }}</div>
              <van-icon @click="showConfirm = true" name="cross" />
            </div> -->
            <div v-if="param.type == 6 && bf31()" class="tp">包月团课权益将于3月1日上线，上线后方可激活使用。</div>
            <div class="box-item" v-for="(v, k) in cardList" :key="k">
              <div @click="jumpCard(v)" class="item" :style="`background-image: url(${v.backgroundImgUrl})`">
                <div>
                  <div class="name">{{ v.cardName }}</div>
                </div>
                <div class="date-info row-start-end">
                  <div>
                    <p :style="{ color: v.status === 'ACTIVE' ? '#FFDE00' : '#FFFFFF' }">{{ v.statusName }}</p>
                  </div>
                  <div v-if="v.useValidityStr">
                    <p>{{ v.useValidityStr }}</p>
                  </div>
                  <div v-if="v.usePlace">
                    <p>{{ v.usePlace }}</p>
                  </div>
                  <div v-if="v.levelStr">
                    <p>{{ v.levelStr }}</p>
                  </div>
                  <div v-if="v.giveCardStr">
                    <p>{{ v.giveCardStr }}</p>
                  </div>
                  <div v-if="v.payType === 'AUTO_RENEW'">
                    <p>{{ v.isAutoRenew === 1 ? '自动续费（已开通）' : '自动续费（已关闭）' }}</p>
                  </div>
                  <div v-if="v.payType === 'ALI_PRE_ENJOY'">
                    <p class="blue">{{ v.isAutoRenew === 1 ? '芝麻先享（已开通）' : '芝麻先享（已关闭）' }}</p>
                  </div>
                  <div v-if="v.payType === 'ALI_SAFE_PAY'">
                    <p class="blue">{{ v.isAutoRenew === 1 ? '安心付（已开通）' : '安心付（已关闭）' }}</p>
                  </div>

                </div>
              </div>
              <!-- 按钮置灰  状态处于待激活但不能开卡 -->
              <div class="bottom-btn row-end-center">
                <template v-if="v.type != 6">
                  <p v-if="v.status === 'WAIT_RECEIVE' || (['WAIT_ACTIVE', 'WAIT_OPEN'].includes(v.status) && !v.isGiveOther)"
                    @click.stop="cardToast(v, 1)"
                    :class="['row-center-center', v.status !== 'WAIT_RECEIVE' ? 'gray' : '']">
                    {{ v.status === 'WAIT_RECEIVE' ? '赠送中' : '赠送好友' }}
                    <van-icon v-if="v.status === 'WAIT_RECEIVE'" name="arrow" />
                  </p>
                  <p v-else-if="v.isGiveOther" @click="share(v.equityCardUserId)" class="row-center-center">赠送好友</p>
                </template>
                <p v-if="v.status === 'WAIT_RECEIVE' || (['WAIT_ACTIVE', 'WAIT_OPEN'].includes(v.status) && !v.isNowActive)"
                  @click.stop="cardToast(v, 2)" class="row-center-center active gray">激活使用</p>
                <p v-else-if="v.isNowActive" @click="activate(v)" class="row-center-center active"
                  :class="{ dis: v.type == 6 && bf31() }">激活使用
                </p>
              </div>
              <span v-if="v.bottomWarn" class="tips-text row-end-center">{{ v.bottomWarn }}</span>
            </div>
          </div>
          <div class="empty" v-else>
            <img src="https://img.chaolu.com.cn/MINI/icon/empty_icon.png" alt="" />
            <div>暂无可用的{{ cardTypeName }}<br />购买{{ cardTypeName }}可享超值优惠</div>
          </div>
        </van-tab>
        <van-tab title="已失效" name="LOSE">
          <div class="list" v-if="cardList.length">
            <div class="box-item" v-for="(v, k) in cardList" :key="k">
              <div @click="jumpCard(v)" class="item" :style="`background-image: url(${v.backgroundImgUrl})`">
                <div>
                  <div class="name">{{ v.cardName }}</div>
                </div>
                <div class="date-info row-start-end">
                  <div>
                    <p :style="{ color: v.status === 'ACTIVE' ? '#FFDE00' : '#FFFFFF' }">{{ v.statusName }}</p>
                  </div>
                  <div v-if="v.useValidityStr">
                    <p>{{ v.useValidityStr }}</p>
                  </div>
                  <div v-if="v.usePlace">
                    <p>{{ v.usePlace }}</p>
                  </div>
                  <div v-if="v.levelStr">
                    <p>{{ v.levelStr }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="empty">
            <img src="https://img.chaolu.com.cn/MINI/icon/empty_icon.png" alt="" />
            <div>暂无失效的权益卡</div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <Activate :userInfo="{ userId: userId, token: token }" :show.sync="showActivate"
      :user-equity-card-id="equityCardUserId" @confirm="getCardList" :cardType="cardType"></Activate>
    <!-- <confirm type="2" :showConfirm="showConfirm" title="是否确认关闭" text="若下次想升级，可在包月私教卡的卡详情最底部找到我哦" :btnTextArr="['我再想想', '7天内不再提示']" @firstBtn="cancel" @secondBtn="confirmSure" /> -->
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack, appOpenWeb } from '@/lib/appMethod'
import headeBar from '@/components/app/headBar'
import confirm from '@/components/confirm/confirm'
import { status } from '@/views/month-card/const'
import Activate from './components/activate.vue'
export default {
  data() {
    return {
      cardList: [],
      typeList: [],
      warn: undefined, // 顶部提醒信息

      active: 0,
      status: status,
      equityCardUserId: '',
      showActivate: false,
      cardType: 0, // 0 自助卡，  1 团课卡 2 私教
      param: {
        packageStatus: 'USE',
        token: '',
        type: '',
        userId: '',
      },
      showConfirm: false,
    }
  },
  mixins: [userMixin],
  components: { headeBar, Activate, confirm },
  async created() {
    initBack() //劫持后退
    await this.$getAllInfo()
    this.param.userId = this.userId
    this.param.token = this.token
    this.param.type = this.$route.query.type ? this.$route.query.type * 1 : ''
    this.getCardList()
  },
  mounted() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.getCardList()
      }
    })
  },
  watch: {
    $route(to, from) {
      this.getCardList()
    }
  },
  computed: {
    cardTypeName() {
      switch (this.param.type) {
        case '':
          return '权益卡'
        case 0:
          return '自助卡'
        case 1:
          return '团课卡'
        case 2:
          return '包月私教卡'
        case 3:
          return '超鹿月卡'
        case 6:
          return '包月团课卡'
        default:
          return ''
      }
    },
  },
  methods: {
    bf31() {
      return new Date() < new Date('2025/03/01')
    },
    changeTab(v) {
      console.log(v)
      this.param.packageStatus = v
      this.getCardList()
    },
    changeType(type) {
      this.param.type = type
      this.getCardList()
    },
    // 获取卡列表
    getCardList() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      this.param.type = this.param.type || ''
      this.$axios
        .post(`${this.baseURLApp}/user/equityCard/equityCardUserSearch/v3`, this.param)
        .then((res) => {
          this.cardList = res.data.equityCardUsers ?? []
          this.typeList = res.data.titles ?? []
          this.typeList.map((item) => {
            item.type = item.type || ''
          })
          this.warn = res.data.warn
        }).catch(() => {
          this.cardList = [];
        })
        .finally((r) => {
          this.$toast.clear()
        })
    },
    jumpCard(v) {
      appOpenWeb('', `${window.location.origin}/#/month-card/my-card-detail?userId=1&token=1&userMembershipCardId=${v.equityCardUserId}&cityId=3`)
    },
    jumpUpgrade() {
      appOpenWeb('', `${window.location.origin}/#/superdeer-activity/sell-membership/coach-upgrade?userId=1&token=1`)
    },
    newAppBack,
    differenceDate(start, end) {
      if (!start || !end) {
        return 0
      }
      return ((new Date(end.replace(/\./g, '/')).getTime() - new Date(start.replace(/\./g, '/')).getTime()) / 24 / 60 / 60 / 1000 + 1).toFixed(0)
    },
    activate(item) {
      this.equityCardUserId = item.equityCardUserId
      this.cardType = item.type
      this.showActivate = true
    },

    // 赠送卡
    share(equityCardUserId) {
      appOpenWeb('', `${window.location.origin}/#/give-equity-card?userId=1&token=1&recordId=${equityCardUserId}`)
    },
    cardToast(v, type) {
      if (v.status === 'WAIT_RECEIVE' && type == 1) {
        appOpenWeb('', `${window.location.origin}/#/give-equity-card/record?userId=1&token=1`)
        return
      }

      let toast = ''
      if (v.status === 'WAIT_RECEIVE') {
        toast = '当前卡在赠送中，无法激活'
      } else if (v.isGiveCard && type === 2) {
        toast = '当前卡为附赠卡，无法激活'
      } else if (!v.isNowActive && v.type === 6 && type === 2) {
        toast = '至多生效一张包月团课卡'
      }
      toast && this.$toast(toast)
    },

    cancel() {
      this.showConfirm = false
    },
    confirmSure() {
      this.$axios.post(`${this.baseURLApp}/user/equityCard/myEquityCardPackageIgnoreWarn`, {
        userId: this.userId,
        token: this.token,
        warnType: this.warn.warnType,
      })
      this.showConfirm = false
      this.warn = undefined
    },
  },
}
</script>

<style lang="less" scoped>
.page-rooter-fff {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .page-content {
    //flex-grow: 1;
    flex: 1 1 0;
    overflow: hidden;
  }

  .com_headerbar {
    flex-shrink: 0;
  }
}

.top-nav {
  padding: 28px 32px 16px;
  overflow: auto;
  flex-shrink: 0;

  &>div {
    //width: 122px;
    height: 50px;
    background: #f5f5f5;
    border-radius: 4px;
    font-size: 22px;
    line-height: 22px;
    color: #242831;
    box-sizing: border-box;
    margin-right: 20px;
    padding: 0 24px;
    border: 2px solid #f5f5f5;
    flex-shrink: 0;

    &.active {
      border-color: #242831;
      background-color: white;
      font-weight: bold;
    }
  }
}

.warn {
  width: 686px;
  box-sizing: border-box;
  height: 96px;
  padding: 0 24px;
  margin: 0 auto 36px;
  background: #f0f5ff;
  border-radius: 12px 12px 12px 12px;
  border: 0.5px solid rgba(154, 161, 169, 0.7);

  img {
    width: 48px;
    margin: 0 12px 0 0;
  }

  .btn {
    padding: 0 24px;
    height: 48px;
    margin: 0 24px;
    color: #fff;
    background: #242831;
    border-radius: 4px;
  }
}

.bottom-btn {
  padding: 0 28px;

  p {
    width: 144px;
    height: 56px;
    border-radius: 4px;
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
    border: 1px solid #6c727a;
    box-sizing: border-box;
    margin: 24px 0 24px 20px;

    &.active {
      border-color: #ff6e00;
      background: #ff6e00;
      color: #ffffff;
    }

    &.gray {
      background: #eeeeee;
      color: #9aa1a9;
      border-color: #eee;

      &.active {
        border-color: #eeeeee;
        background: #eee;
        color: #9aa1a9;
      }
    }
  }
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  min-height: 80vh;

  img {
    width: 320px;
    height: 320px;
  }

  div {
    text-align: center;
    margin-top: 20px;
    font-size: 26px;
    line-height: 40px;
    color: #6c727a;
  }

  p {
    background: url('https://img.chaolu.com.cn/ACT/icon/right_arrows.png') no-repeat 392px center #ffde00;
    background-size: 40px 40px;
    width: 460px;
    height: 94px;
    border-radius: 8px;
    margin-top: 64px;
  }
}

.list {
  height: 100%;
  width: 100%;
  padding: 20px 32px 150px;
  box-sizing: border-box;
  overflow-y: auto;

  .tp {
    font-size: 24px;
    color: #F03C18;
    margin-bottom: 26px;
  }

  .box-item+.box-item {
    margin-top: 32px;
  }

  .box-item {
    border-radius: 16px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;
    border: 1px solid #eee;
  }

  .item {
    &.expired {
      background-color: #eee;
    }

    width: 100%;
    height: 320px;
    display: flex;
    padding-left: 28px;
    padding-top: 24px;
    box-sizing: border-box;
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .name {
      font-size: 44px;
      font-family: PuHuiTi;
      color: #ffffff;
      width: 8em;
      line-height: 52px;
      margin-bottom: 26px;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
      -webkit-background-clip: text;
    }

    .renewal {
      width: 152px;
      height: 58px;
      background: #3c454e;
      border-radius: 4px;
      font-weight: 500;
      color: #ffffff;
      font-size: 24px;
    }

    .use-tips {
      font-size: 22px;
      color: #ffffff;
      line-height: 26px;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    }

    .date-info {
      position: absolute;
      bottom: 28px;
      left: 28px;
      width: 622px;
      flex-wrap: wrap;

      div {
        flex: none;
        margin-top: 10px;
      }

      p {
        color: #ffffff;
        font-weight: bold;
        height: 36px;
        font-size: 20px;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.5);
        padding: 0 12px;
        display: flex;
        align-items: center;
        margin-right: 16px;

        &.blue {
          background: #1777ff;
        }
      }
    }

    .status {
      position: absolute;
      top: 40px;
      right: 32px;
      font-weight: bold;
      color: #fff040;
      font-size: 24px;
      background-color: rgba(0, 0, 0, 0.6);
      padding: 0 12px;
      height: 44px;
      line-height: 44px;
      border-radius: 8px;

      &.gray {
        color: #9aa1a9;
      }
    }
  }
}

.dis {
  pointer-events: none;
  border-color: #eeeeee!important;
  background: #eee!important;
  color: #9aa1a9!important;
}

/deep/ .van-tab--active {
  font-weight: bold;
}

/deep/ .van-tabs {
  height: 100%;
}

/deep/ .van-hairline--top-bottom::after {
  border-top: none;
  border-color: #eee;
}

/deep/ .van-tabs__content,
/deep/ .van-tab__pane {
  height: calc(100% - 44px);
  display: flex;
  flex-direction: column;
}

.tips-text {
  text-align: right;
  padding-bottom: 24px;
  font-size: 22px;
  line-height: 22px;
  color: #f03c18;
  font-weight: 400;
  padding-right: 28px;
}
</style>
